<template>
  <div>
    <edit-sign :sign="sign" v-if="edit && !add" @cancel="edit = false" @reset="getData" @del="confirmDelItem"/>
    <add-sign v-if="add" @cancel="add = false" @reset="getData"/>
    <div v-if="!edit && !add">
      <breadcrumb name="Dashboard Signs" title="All Dashboard Signs" subtitle="List"/>
      <div id="data-list-thumb-view" class="data-list-container">
        <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="products">

          <!-- Table TabHeader -->
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
            <div class="flex flex-wrap-reverse items-center data-list-btn-container">
              <add-new-button @click="add = true"/>
            </div>
            <table-header
              :current-page="currentPage"
              :items-per-page="itemsPerPage"
              :length="products.length"
              :queried-items="queriedItems"
              @onChangeItemsPerPage="itemsPerPage = $event"/>
          </div>

          <template slot="thead">
            <vs-th>Image</vs-th>
            <vs-th sort-key="title">Name</vs-th>
            <vs-th sort-key="price">Description</vs-th>
            <vs-th>Actions</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

              <image-row :data="tr.icon" />
              <string-row :data="tr.name_en" />
              <html-row :data="tr.description_en" />

              <actions-row
                @onEditClicked="editItem(tr)"
                @onDeleteClicked="confirmDelItem(tr.id)"/>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </div>
</template>

<script>
  import {ChevronsRightIcon, HomeIcon} from 'vue-feather-icons';
  import EditSign from './edit-sign';
  import AddSign from './add-sign';
  import AddNewButton from "../../../components/form/addnew-button";
  import Breadcrumb from "../../../components/general/breadcrumb";
  import TableHeader from "../../../components/table/table-header";
  import ImageRow from "../../../components/table/rows/image-row";
  import StringRow from "../../../components/table/rows/string-row";
  import StatusRow from "../../../components/table/rows/status-row";
  import ActionsRow from "../../../components/table/rows/actions-row";
  import HtmlRow from "../../../components/table/rows/html-row";

  export default {
    name: 'dashboard-signs',
    components: {
      HtmlRow,
      ActionsRow, StatusRow, StringRow, ImageRow, TableHeader, Breadcrumb, AddNewButton, AddSign, EditSign, HomeIcon, ChevronsRightIcon},
    data() {
      return {
        edit: false,
        add: false,
        selected: [],
        mainData: {},
        itemsPerPage: 20,
        isMounted: false,
        addNewDataSidebar: false,
        sidebarData: {},
        part: {},
        signs: [],
        sign: {
          id: null,
          name_en: '',
          name_ar: '',
          description_en: '',
          description_ar: '',
          icon: '',
          created_at: '',
          mobile_active: null,
          web_active: null
        }
      };
    },
    computed: {
      currentPage() {
        if (this.isMounted) {
          return this.$refs.table.currentx;
        }
        return 0;
      },
      products() {
        return this.signs;
      },
      queriedItems() {
        return this.$refs.table ? this.$refs.table.queriedResults.length : this.products.length;
      }
    },
    methods: {
      confirmDelItem(id) {
        this.$vs.dialog({
          type: 'confirm',
          accept: () => this.delItem(id),
          color: 'danger',
          title: 'Confirm',
          text: 'You are going to Delete these data!'
        });
      },
      delItem(id) {
        this.$vs.loading();
        this.$httpSignsDashboard.delete(`dashboard-signs/${id}`)
          .then(r => {
            console.log(r);
            this.$vs.loading.close();
            this.$vs.notify({
              title: 'Success',
              text: 'Data is Deleted Successfully!',
              color: 'success'
            });
            this.getData();

          })
          .catch(() => {
            //console.log(e);
            this.$vs.loading.close();
            this.$vs.notify({
              title: 'Error',
              text: 'Error is occurred!\nCouldn\'t delete Data!',
              color: 'danger'
            });
          });
      },
      getData() {
        this.$vs.loading();
        this.$httpSignsDashboard.get(`/dashboard-signs`)
          .then(r => {
            this.mainData = r.data.data;
            this.$httpSignsDashboard.get(`/dashboard-signs?per_page=${this.mainData.total}`)
              .then(r => {
                this.$vs.loading.close();
                this.signs = r.data.data.data;
                console.log({signs: this.signs});
                this.edit = false;
                this.add = false;
              })
              .catch(() => {
                this.$vs.loading.close();
                //console.log(e);
              });
          })
          .catch(() => {
           // console.log(e);
            this.$vs.loading.close();
            this.$vs.notify({
              title: 'Error',
              text: 'Couldn\'t Load Data!',
              color: 'danger'
            });
          });
      },
      openLink(link) {
        if (!/^http/.test(link)) {
          window.open('http://' + link, '_blank');
        } else {
          window.open(link, '_blank');
        }

      },
      getOrderStatusColor(status) {
        if (status == 0) return 'warning';
        if (status == 1) return 'success';
        return 'primary';
      },
      getOrderStatusState(status) {
        if (status === 0) return 'Unavailable';
        if (status == 1) return 'Available';
        return 'Unknown';
      },
      editItem(item) {
        this.sign = item;
        this.edit = true;
      }
    },
    mounted() {
      this.getData();
      this.isMounted = true;
    }
  };
</script>

<style lang="scss">

  @import "../../basic-styles/mixins";

  %flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .img-fluid {
    border: 1px solid #00000030;
    height: auto;
    width: 200px;
    max-width: 100%;
    border-radius: 20px;
    @include margin-x(20px);
  }

  .images {
    @include margin-y(30px);
    text-align: center;
  }

  .optionBtns {
    @include margin-x(20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .save {
      position: relative;
      left: 25px;
    }
  }

  .input {
    width: 100%;
    @include margin-y(30px)
  }

  .arabic, .english {
    @extend %flex;
    flex-flow: column;
    width: 100%;
    padding: 20px;
  }

  h1 {
    margin-bottom: 25px;
  }

  .inputs {
    @extend %flex;
  }

  ul {
    li {
      display: inline;
    }
  }

  .clr {
    clear: both;
  }

  $primaryColor: #054f7c;

  .breadcrumb {
    margin: 20px;
  }

  .back-to {
    cursor: pointer;
    color: $primaryColor;
    font-weight: bold;
  }

  .main-component {
    color: $primaryColor;
  }

  .required {
    color: red;
    font-size: 1.5rem;
  }

  .mandatory {
    color: red;
  }

  .arabic, .english {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
  }

  .body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  .img-uploader {
    height: 200px;
    max-width: 100%;
    width: auto;
    border-radius: 20px;
    padding: 10px
  }

  .custom-class {
    color: $primaryColor;
  }

  .title {
    font-size: 2rem;
    font-weight: 400;
  }

  .separator {
    font-size: 2rem;
    font-weight: 100;
    color: #ccc;
  }

  .label {
    color: #000;
  }


  #data-list-thumb-view {
    .vs-con-table {

      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap-reverse;
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            & + i {
              left: 1rem;
            }

            &:focus + i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;


        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

          td {
            padding: 10px;

            &:first-child {
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }

            &:last-child {
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }

            &.img-container {
              // width: 1rem;
              // background: #fff;

              span {
                display: flex;
                justify-content: flex-start;
              }

              .product-img {
                height: 110px;
              }
            }
          }

          td.td-check {
            padding: 20px !important;
            display: none !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        th.td-check {
          padding: 0 15px !important;
          display: none !important;
        }

        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }

  .product-img {
    height: 110px;
    width: 110px;
    object-fit: cover;
  }
</style>
