<template>
  <div>
    <breadcrumb name="Dashboard Signs" title="All Dashboard Signs" subtitle="Add"
                @onTitleClicked="$emit('cancel')"/>
    <div class="body">
      <h6 class="mandatory">Fields marked with (*) are mandatory</h6>
      <div>
        <div class="english">
          <vs-input label="English Title*" v-model="name_en"
                    size="medium"
                    class="input" :danger="flags.title_en" danger-text="This field is mandatory"/>
          <vue-editor
            v-model="description_en"
            :editorToolbar="customToolbar"
            v-if="!showHTML"
          />
          <textarea v-model="description_en"  v-if="showHTML"></textarea>

        </div>
        <div class="arabic">
          <vs-input label="Arabic Title*" v-model="name_ar"
                    size="medium"
                    class="input" :danger="flags.title_ar" danger-text="This field is mandatory"/>
          <vue-editor
            v-model="description_ar"
            :editorToolbar="customToolbar"
            :editorOptions="editorOptions"
            ref="vue-editor-quill"
            v-if="!showHTML"
          />
          <textarea v-model="description_ar"  v-if="showHTML"></textarea>
        </div>
      </div>
      <div>
        <div class="clr"/>
      </div>
      <h6 v-if="noImage" class="error">There must be at least one image!</h6>
      <img-upload :images="images" @image_uploaded="saveImage" v-if="imageUpload"
                  title="Upload Images" parent="edit-sign" :key="imagesCounter"/>
      <div class="optionBtns">
        <vs-button :disabled="invalidForm" class="mr-5 save" icon-pack="feather" icon="icon-save"
                   @click="confirmSaveDate">Save
        </vs-button>
        <vs-button class="ml-5 cancel" type="border" color="primary" @click="$emit('reset')">
          Reset
        </vs-button>
        <vs-button class="ml-5" type="border" @click="showHTML = !showHTML">{{showHTML ? "Hide HTML" : "Show HTML"}}</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { VueEditor } from "vue2-editor";
  import ImgUpload from "../../../components/general/img-upload";
  import { ChevronsRightIcon, HomeIcon } from "vue-feather-icons";
  import Breadcrumb from "../../../components/general/breadcrumb";

  export default {
    name: "add-sign",
    components: { Breadcrumb, ImgUpload, HomeIcon, ChevronsRightIcon, VueEditor },
    data() {
      return {
        showHTML: false,
        editorOptions: {
          direction: "rtl"
        },
        imageUpload: false,
        baseImagesURL: "http://mansour-dev.s3.us-east-2.amazonaws.com/",
        name_en: "",
        name_ar: "",
        description_en: "",
        description_ar: "",
        icon: "",
        images: [],
        imagesCounter: 0,
        customToolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: 1 }, { header: 2 }],
          ["bold", "italic", "underline", "strike"],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ color: [] }, { background: [] }],
          ["link", "formula"],
          ["clean"]
        ],
        imageData: {
          category: "config"
        },
        uploadImageHeaders: {
          "Access-Control-Allow-Origin": "* | http://192.241.234.75:9109"
        },
        flags: {
          title_ar: false,
          title_en: false,
          text_ar: false,
          text_en: false
        }
      };
    },
    methods: {
      saveImage(imagesArray) {
        this.images = imagesArray;
        this.icon = this.images[0];
        this.imagesCounter++;
      },
      confirmSaveDate() {
        this.$vs.dialog({
          title: "Confirm",
          text: "You are going to save these data!",
          color: "primary",
          accept: () => this.saveData(),
          type: "confirm"
        });
      },
      saveData() {
        this.$vs.loading();

        this.icon = this.icon.split(this.baseImagesURL).pop();

        // let payload = {
        //     name_ar: this.name_ar,
        //     description_ar: this.description_ar,
        //     name_en: this.name_en,
        //     description_en: this.description_en,
        //     icon: this.icon
        // };

        let data = new FormData();
        data.append("name_ar", this.name_ar);
        data.append("description_ar", `<body dir="rtl" style="text-align: right">${this.description_ar}</body>`);
        data.append("name_en", this.name_en);
        data.append("description_en", this.description_en);
        data.append("icon", this.icon);


        this.$httpSignsDashboard.post(`dashboard-signs`, data)
          .then(r => {
            this.$emit("reset");
            this.$vs.loading.close();
            console.log(r);
            this.$vs.notify({
              title: "Saved",
              text: "Data is Saved Successfully",
              color: "primary"
            });
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Error is occurred!\nCouldn't Save Data!",
              color: "danger"
            });
          });
      },
      successUpload(e) {
        console.log(e);
        let imageURL = this.baseImagesURL + JSON.parse(e.target.response).data;
        this.images.push(imageURL);
        console.log(this.images);
      },
      failUpload() {
        this.$vs.notify({
          title: "Error",
          text: "Failed to Upload Image!",
          color: "danger"
        });
      },
      confirm_delete_image(index) {
        this.$vs.dialog({
          type: "confirm",
          accept: () => this.delete_image(index),
          color: "danger",
          title: "Delete Image",
          text: "You are going to delete this image"
        });
      },
      delete_image(index) {
        this.images.splice(index, 1);
      }
    },
    computed: {
      invalidForm() {
        if (this.flags.title_ar || this.flags.title_en || this.flags.text_ar || this.flags.text_en || this.noImage) {
          return true;
        } else {
          return false;
        }
      },
      noImage() {
        return this.images === 0;
      }
    },
    watch: {
      name_ar(v) {
        v === "" ? this.flags.title_ar = true : this.flags.title_ar = false;
      },
      name_en(v) {
        v === "" ? this.flags.title_en = true : this.flags.title_en = false;
      },
      description_ar(v) {
        v.replace(/(<([^>]+)>)/ig, "") === "" ? this.flags.text_ar = true : this.flags.text_ar = false;
        v.replace(/(<([^>]+)>)/ig, "").length > 3000 ? this.flags.lengthExceeded_ar = true : this.flags.lenghtExceeded_ar = false;
      },
      description_en(v) {
        v.replace(/(<([^>]+)>)/ig, "") === "" ? this.flags.text_en = true : this.flags.text_en = false;
        v.replace(/(<([^>]+)>)/ig, "").length > 3000 ? this.flags.lengthExceeded_en = true : this.flags.lengthExceeded_en = false;
      }
    },
    mounted() {
      this.imageUpload = true;
      // this.$refs["vue-editor-quill"].quill.format("direction", "rtl");
    }
  };
</script>

<style scoped lang="scss">
  @import "../../basic-styles/mixins";

  %flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .img-fluid {
    border: 1px solid #00000030;
    height: auto;
    width: 200px;
    max-width: 100%;
    border-radius: 20px;
    @include margin-x(20px);
  }

  .images {
    @include margin-y(30px);
    text-align: center;
  }

  .optionBtns {
    @include margin-x(20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .save {
      position: relative;
      left: 25px;
    }
  }

  .input {
    width: 100%;
    @include margin-y(30px)
  }

  .arabic, .english {
    @extend %flex;
    flex-flow: column;
    width: 100%;
    padding: 20px;
  }

  h1 {
    margin-bottom: 25px;
  }

  .inputs {
    @extend %flex;
  }

  ul {
    li {
      display: inline;
    }
  }

  .clr {
    clear: both;
  }

  $primaryColor: #054f7c;

  .breadcrumb {
    margin: 20px;
  }

  .back-to {
    cursor: pointer;
    color: $primaryColor;
    font-weight: bold;
  }

  .main-component {
    color: $primaryColor;
    cursor: pointer;
  }

  .required {
    color: red;
    font-size: 1.5rem;
  }

  .mandatory {
    color: red;
  }

  .arabic, .english {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
  }

  .body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  .img-uploader {
    height: 200px;
    max-width: 100%;
    width: auto;
    border-radius: 20px;
    padding: 10px
  }

  .custom-class {
    color: $primaryColor;
  }

  .title {
    font-size: 2rem;
    font-weight: 400;
  }

  .separator {
    font-size: 2rem;
    font-weight: 100;
    color: #ccc;
  }

  .label {
    color: #000;
  }

  .centered {
    text-align: center;
  }

  textarea {
    width: 100%;
    height: 350px;
  }

</style>
